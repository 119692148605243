import React from "react";
import { Layout } from "../layouts/Layout";
import App from "../components/App";

function AppPage() {
  return (
    <Layout>
      <App />
    </Layout>
  );
}

export default AppPage;
